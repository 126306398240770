import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import LegalPageBase from './BaseLegalPage';
import Spacer from '../../layout/Spacer';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const Content = ({ location }) => {
  const myUplinkRef = useRef(null);
  const myUplinkPRORef = useRef(null);

  const scrollIntoViewOptions = { behavior: 'smooth', block: 'start' };

  const refs = {
    myUplink: myUplinkRef,
    myUplinkPRO: myUplinkPRORef,
  };

  useEffect(() => {
    const target = location.hash.replace('#', '');
    if (refs[target] && refs[target].current) {
      refs[target].current.scrollIntoView(scrollIntoViewOptions);
    }
  }, [location]);

  const handleScroll = (e, target) => {
    e.preventDefault();

    if (refs[target] && refs[target].current) {
      refs[target].current.scrollIntoView(scrollIntoViewOptions);
    }
  };

  return (
    <div className="privacy-policy">
      <p className="title-links-container">
        <a href="#myUplink" onClick={e => handleScroll(e, 'myUplink')}>
          <FormattedMessage id="privacyPolicy.1.myUplink.link" />
        </a>

        <Spacer x={30} inline />

        <a href="#myUplinkPRO" onClick={e => handleScroll(e, 'myUplinkPRO')}>
          <FormattedMessage id="privacyPolicy.2.myUplinkPRO.link" />
        </a>
      </p>
      <h1 id="myUplink" ref={myUplinkRef}>
        <FormattedMessage id="privacyPolicy.3.myUplinkInformation.heading" />
      </h1>
      <p>
        <FormattedMessage id="privacyPolicy.4.companyInfo.paragraph" />
      </p>
      <p className="text-link-container">
        <FormattedMessage
          id="privacyPolicy.5.contactInfo.paragraph"
          values={{
            emailLink: <a href="mailto:gdpr@myuplink.com">gdpr@myuplink.com</a>,
          }}
        />
      </p>
      <h2>
        <FormattedMessage id="privacyPolicy.6.dataProcessing.heading" />
      </h2>
      <p>
        <FormattedMessage id="privacyPolicy.7.dataProcessingInfo.paragraph" />
      </p>
      <strong>
        <FormattedMessage id="privacyPolicy.8.deliverServices.heading" />
      </strong>
      <p>
        <FormattedMessage id="privacyPolicy.9.deliverServicesInfo.paragraph" />
      </p>
      <strong>
        <FormattedMessage id="privacyPolicy.10.accessForInstaller.heading" />
      </strong>
      <p>
        <FormattedMessage id="privacyPolicy.11.accessForInstallerInfo.paragraph" />
      </p>
      <strong>
        <FormattedMessage id="privacyPolicy.12.marketing.heading" />
      </strong>
      <p>
        <FormattedMessage id="privacyPolicy.13.marketingInfo.paragraph" />
      </p>
      <strong>
        <FormattedMessage id="privacyPolicy.14.troubleshooting.heading" />
      </strong>
      <p>
        <FormattedMessage id="privacyPolicy.15.troubleshootingInfo.paragraph" />
      </p>
      <strong>
        <FormattedMessage id="privacyPolicy.16.tipsAndAdvice.heading" />
      </strong>
      <p>
        <FormattedMessage id="privacyPolicy.17.tipsAndAdviceInfo.paragraph" />
      </p>
      <strong>
        <FormattedMessage id="privacyPolicy.18.shopWithUs.heading" />
      </strong>
      <p>
        <FormattedMessage id="privacyPolicy.19.shopWithUsInfo.paragraph" />
      </p>
      <strong>
        <FormattedMessage id="privacyPolicy.20.sendMessage.heading" />
      </strong>
      <p>
        <FormattedMessage id="privacyPolicy.21.sendMessageInfo.paragraph" />
      </p>
      <h2>
        <FormattedMessage id="privacyPolicy.22.whoHasAccess.heading" />
      </h2>
      <p className="text-link-container">
        <FormattedMessage
          id="privacyPolicy.23.whoHasAccessInfo.paragraph"
          values={{
            sccLink: (
              <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en
              </a>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage id="privacyPolicy.24.smartHomeSolutions.paragraph" />
      </p>
      <p>
        <FormattedMessage id="privacyPolicy.25.transferToTechnician.paragraph" />
      </p>
      <p>
        <FormattedMessage id="privacyPolicy.26.noSellData.paragraph" />
      </p>
      <p>
        <FormattedMessage id="privacyPolicy.27.noDNTResponse.paragraph" />
      </p>
      <h2>
        <FormattedMessage id="privacyPolicy.28.dataRetention.heading" />
      </h2>
      <p>
        <FormattedMessage id="privacyPolicy.29.dataRetentionInfo.paragraph" />
      </p>
      <h2>
        <FormattedMessage id="privacyPolicy.30.yourRights.heading" />
      </h2>
      <p>
        <FormattedMessage id="privacyPolicy.31.withdrawConsent.paragraph" />
      </p>
      <p>
        <FormattedMessage id="privacyPolicy.32.dataProtectionRights.paragraph" />
      </p>
      <p>
        <FormattedMessage id="privacyPolicy.33.dataPortability.paragraph" />
      </p>
      <p className="text-link-container">
        <FormattedMessage
          id="privacyPolicy.34.exerciseRights.paragraph"
          values={{
            emailLink: <a href="mailto:gdpr@myuplink.com">gdpr@myuplink.com</a>,
          }}
        />
      </p>
      <p>
        <FormattedMessage id="privacyPolicy.35.policyEffectiveDate.paragraph" />
      </p>
      <h1 id="myUplinkPRO" ref={myUplinkPRORef}>
        <FormattedMessage id="privacyPolicy.36.myUplinkPROInformation.heading" />
      </h1>
      <p>
        <FormattedMessage id="privacyPolicy.37.companyInfoPRO.paragraph" />
      </p>
      <h2>
        <FormattedMessage id="privacyPolicy.38.dataController.heading" />
      </h2>
      <p className="text-link-container">
        <FormattedMessage
          id="privacyPolicy.39.dataControllerInfo.paragraph"
          values={{
            address: 'Hannabadsvägen 5, 285 32 Markaryd',
            emailLink: <a href="mailto:gdpr@myuplink.com">gdpr@myuplink.com</a>,
          }}
        />
      </p>

      <h2>
        <FormattedMessage id="privacyPolicy.40.purposeAndLegalBasis.heading" />
      </h2>
      <p>
        <FormattedMessage id="privacyPolicy.41.purposeAndLegalBasisInfo.paragraph" />
      </p>
      <h2>
        <FormattedMessage id="privacyPolicy.42.accessToPersonalData.heading" />
      </h2>
      <p className="text-link-container">
        <FormattedMessage
          id="privacyPolicy.43.accessToPersonalDataInfo.paragraph"
          values={{
            sccLink: (
              <a
                className="text-link"
                href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en
              </a>
            ),
          }}
        />
      </p>

      <p>
        <FormattedMessage id="privacyPolicy.44.noSellDataPRO.paragraph" />
      </p>
      <p>
        <FormattedMessage id="privacyPolicy.45.noDNTResponsePRO.paragraph" />
      </p>
      <h2>
        <FormattedMessage id="privacyPolicy.46.dataRetentionPRO.heading" />
      </h2>
      <p>
        <FormattedMessage id="privacyPolicy.47.dataRetentionInfoPRO.paragraph" />
      </p>
      <h2>
        <FormattedMessage id="privacyPolicy.48.yourRightsPRO.heading" />
      </h2>
      <p>
        <FormattedMessage id="privacyPolicy.49.dataProtectionRightsPRO.paragraph" />
      </p>
      <p className="text-link-container">
        <FormattedMessage
          id="privacyPolicy.50.exerciseRightsPRO.paragraph"
          values={{
            emailLink: <a href="mailto:gdpr@myuplink.com">gdpr@myuplink.com</a>,
          }}
        />
      </p>

      <p>
        <FormattedMessage id="privacyPolicy.51.policyEffectiveDatePRO.paragraph" />
      </p>
    </div>
  );
};

Content.propTypes = {
  location: PropTypes.object.isRequired,
};

export const PrivacyPolicyPageContentWithRouter = withRouter(Content);

const PrivacyPolicyLegalPage = props => <LegalPageBase {...props} content={<PrivacyPolicyPageContentWithRouter />} />;

export default PrivacyPolicyLegalPage;

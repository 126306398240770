import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BrandLogo, SmartDeviceLogo, CompatibleProviders } from '../../layout/Slideshow/CompanyImages/WorksWithBrands';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './works-with.scss';

function WorksWith() {
  return (
    <div className="page-content">
      <div className="works-with-container">
        <h1 className="works-with-container__title">
          <FormattedMessage id="slide.title.works-with" defaultMessage="Works with" />
        </h1>
        <div className="devices-subtitle">
          <FormattedMessage
            id="works.with.description.smart-home-devices"
            defaultMessage="Compatibility with these smart home applications may vary depending on device used."
          />
        </div>
        <SmartDeviceLogo />
        <hr />
        <div className="companies-subtitle">
          <FormattedMessage
            id="works.with.description.companies"
            defaultMessage="Devices from following companies can be connected to myUplink"
          />
        </div>
        <BrandLogo />
        <hr />
        <div className="companies-subtitle">
          <FormattedMessage
            id="works.with.description.compatible-providers"
            defaultMessage="Explore our compatible providers for seamless integrations. Compatibility may vary by device."
          />
        </div>
        <CompatibleProviders />
      </div>
    </div>
  );
}

WorksWith.propTypes = {
  userLoggedIn: PropTypes.bool,
};

export default connect(
  ({ app: { userLoggedIn } }) => ({
    userLoggedIn,
  }),
  {},
)(WorksWith);

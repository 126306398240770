import moment from 'moment';
import { dateTimeFormatString } from '../components/constants/constants';
import { getValueFromLocalStorage, setValueInLocalStorage, deleteValueFromLocalStorage } from '../localStorage';

const getTilesByUserKey = (systemId, userId) => `TILE_USER_CONFIGURATION_${systemId}_${userId}`;
const getPrepopulatedSystemsByUserKey = userId => `PREPOPULATED_SYSTEMS_${userId}`;
const getTilesLastRefreshedByUserKey = (systemId, userId) => `TILES_LAST_REFRESHED_${systemId}_${userId}`;

const defaultTiles = [];

/*
 overwrites all tile configuration
*/
export const write = (tiles, systemId, userId) => {
  setValueInLocalStorage(
    getTilesByUserKey(systemId, userId),
    tiles.map(t => ({ ...t, expanded: false })),
  );
  return Promise.resolve();
};

export const setLastRefreshed = (userId, systemId) => {
  if (!userId || !systemId) {
    return new Promise(resolve => resolve({ status: 400 }));
  }
  const now = moment.utc().format(dateTimeFormatString);
  setValueInLocalStorage(getTilesLastRefreshedByUserKey(systemId, userId), now);
  return Promise.resolve();
};

export const resetAllTilesLastRefreshed = () => {
  const keys = [];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).startsWith('TILES_LAST_REFRESHED_')) {
      keys.push(localStorage.key(i));
    }
  }
  for (let i = 0; i < keys.length; i++) {
    deleteValueFromLocalStorage(keys[i]);
  }
  return Promise.resolve();
};

export const getLastRefreshed = (userId, systemId) => {
  if (!userId || !systemId) {
    return new Promise(resolve => resolve({ status: 400 }));
  }

  let lastRefreshed = getValueFromLocalStorage(getTilesLastRefreshedByUserKey(systemId, userId));
  if (!lastRefreshed) {
    lastRefreshed = moment.utc().subtract(1, 'years').format(dateTimeFormatString);
  }
  return new Promise(resolve => {
    const response = {
      status: 200,
      data: lastRefreshed,
    };

    resolve(response);
  });
};

/*
 removes all tiles by system/user combination
*/
export const remove = (systemId, userId) => {
  const tiles = getValueFromLocalStorage(getTilesByUserKey(systemId, userId));

  if (!tiles) {
    return new Promise(resolve => {
      const response = {
        status: 404,
      };

      resolve(response);
    });
  }

  const lastRefreshed = getValueFromLocalStorage(getTilesLastRefreshedByUserKey(systemId, userId));
  if (lastRefreshed) {
    deleteValueFromLocalStorage(getTilesLastRefreshedByUserKey(systemId, userId));
  }

  deleteValueFromLocalStorage(getTilesByUserKey(systemId, userId));
  return new Promise(resolve => {
    const response = {
      status: 200,
    };

    resolve(response);
  });
};

export const removeByDevice = (systemId, userId, deviceId) => {
  const storageKey = getTilesByUserKey(systemId, userId);
  const tiles = getValueFromLocalStorage(storageKey);

  if (!tiles) {
    return new Promise(resolve => {
      const response = {
        status: 404,
      };

      resolve(response);
    });
  }

  const filteredTiles = tiles.filter(tile => tile.deviceId !== deviceId);
  setValueInLocalStorage(storageKey, filteredTiles);

  const lastRefreshed = getValueFromLocalStorage(getTilesLastRefreshedByUserKey(systemId, userId));
  if (lastRefreshed) {
    deleteValueFromLocalStorage(getTilesLastRefreshedByUserKey(systemId, userId));
  }

  return new Promise(resolve => {
    const response = {
      status: 200,
      tiles: filteredTiles,
    };

    resolve(response);
  });
};

/*
TODO: implement when knowing how its supposed to work
*/
export const get = (systemId, userId) => {
  if (!systemId) {
    return new Promise(resolve => resolve({ status: 400 }));
  }
  let tiles = getValueFromLocalStorage(getTilesByUserKey(systemId, userId)) || defaultTiles;

  // if default value was used
  // to distinguish tile switch on the UI
  if (!getValueFromLocalStorage(getTilesByUserKey(systemId, userId)) && systemId === 'system_2') {
    tiles = [...tiles].reverse();
  }

  return new Promise(resolve => {
    const response = {
      status: 200,
      data: tiles,
    };

    resolve(response);
  });
};

export const getPrepopulationStatus = (userId, systemId) => {
  if (!userId || !systemId) {
    return new Promise(resolve => resolve({ status: 400 }));
  }

  const key = getPrepopulatedSystemsByUserKey(userId);
  const prepopulatedSystems = getValueFromLocalStorage(key) || [];

  return new Promise(resolve => {
    const response = {
      status: 200,
      data: prepopulatedSystems.includes(systemId),
    };

    resolve(response);
  });
};

export const setSystemAsPrepopulated = (userId, systemId) => {
  const key = getPrepopulatedSystemsByUserKey(userId);
  const prepopulatedSystems = getValueFromLocalStorage(key) || [];

  if (!prepopulatedSystems.includes(systemId)) {
    prepopulatedSystems.push(systemId);
  }

  setValueInLocalStorage(key, prepopulatedSystems);
  return Promise.resolve();
};

export const unsetSystemAsPrepopulated = (userId, systemId) => {
  const key = getPrepopulatedSystemsByUserKey(userId);
  let prepopulatedSystems = getValueFromLocalStorage(key) || [];

  if (prepopulatedSystems.includes(systemId)) {
    prepopulatedSystems = prepopulatedSystems.filter(system => system != systemId);
  }

  setValueInLocalStorage(key, prepopulatedSystems);
  return Promise.resolve();
};

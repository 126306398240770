const EXAMPLE_TILE = 'EXAMPLE_TILE';
const SMART_GUIDE = 'smartGuide';
const CHART_TILE = 'chart';

const ENERGY = 'energy';
const FAN_MODE = 'fanMode';
const GEOTHERMAL_LOOP = 'geothermalLoop';
const DOMESTIC_HOT_WATER = 'hotWater';
const HUMIDITY = 'humidity';
const INDOOR_CLIMATE = 'indoorClimate';
const POOL = 'pool';
const SAVINGS = 'savings';
const SETPOINT = 'setpoint';
const SMART_PLUG = 'smartPlug';
const SOLAR_PANEL = 'solar';
const HEAT_PUMP_STATUS = 'status';
const SYSTEM_MODE = 'systemMode';
const TEMPERATURE = 'temperature';
const VENTILATION = 'ventilation';
const WEATHER_OUTDOOR_TEMPERATURE = 'weather';
const ZONING = 'zoning';
const HEATING = 'heating';
const COOLING = 'cooling';
export const ELECTRICAL_PRICE = 'electricalPrice';
export const ELECTRICAL_PRICE_TILE = 'electricalPriceTile';

export const TileTypes = {
  EXAMPLE_TILE,
  SMART_GUIDE,
  CHART_TILE,
  ENERGY,
  FAN_MODE,
  GEOTHERMAL_LOOP,
  DOMESTIC_HOT_WATER,
  HUMIDITY,
  INDOOR_CLIMATE,
  POOL,
  SAVINGS,
  SETPOINT,
  SMART_PLUG,
  SOLAR_PANEL,
  HEAT_PUMP_STATUS,
  SYSTEM_MODE,
  TEMPERATURE,
  VENTILATION,
  WEATHER_OUTDOOR_TEMPERATURE,
  ELECTRICAL_PRICE,
  ZONING,
  HEATING,
  COOLING,
};

const LEFT_HAND_VALUE = 'LEFT_HAND_VALUE';
const RIGHT_HAND_VALUE = 'RIGHT_HAND_VALUE';
const CHART_VALUE = 'CHART_VALUE';

export const TilePropertyKeys = {
  LEFT_HAND_VALUE,
  RIGHT_HAND_VALUE,
  CHART_VALUE,
};

export const getTileProperties = tileType => {
  switch (tileType) {
    case CHART_TILE:
      return [CHART_VALUE];
    default:
      return [LEFT_HAND_VALUE, RIGHT_HAND_VALUE, CHART_VALUE];
  }
};

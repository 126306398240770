import { ELECTRICAL_PRICE } from './TileTypes';

export const expandTile = (tiles, expandedTile) => {
  return tiles.map(obj => (obj.id === expandedTile.id ? { ...obj, expanded: true } : obj));
};

export const minimizeTile = (tiles, minimizedTile) => {
  return tiles.map(obj => (obj.id === minimizedTile.id ? { ...obj, expanded: false } : obj));
};

export const moveTile = (tiles, tile, toIndex) => {
  const newTiles = [...tiles];

  const fromIndex = tiles.findIndex(t => t.id === tile.id);

  newTiles.splice(fromIndex, 1); // removing what you are dragging.
  newTiles.splice(toIndex, 0, tile); // inserting it into hoverIndex.

  return newTiles;
};

export const updateTile = (tiles, updatedTile) => {
  return tiles.map(tile => {
    if (updatedTile.id === tile.id) {
      return { ...tile, ...updatedTile };
    }
    return tile;
  });
};

export const updateElectricalPriceTile = (tiles, data) => {
  const hasChanges = tiles.some(tile => tile.type === ELECTRICAL_PRICE && tile?.properties?.chart?.name !== data.name);

  if (!hasChanges) {
    return tiles;
  }

  return tiles.map(tile =>
    tile.type === ELECTRICAL_PRICE && tile?.properties?.displayParameters
      ? {
          ...tile,
          properties: {
            ...tile.properties,
            chart: {
              ...tile.properties.chart,
              parameter: data,
              name: data.name,
              text: data.name,
            },
            displayParameters: tile.properties.displayParameters.some(dp => dp.id === data.id)
              ? tile.properties.displayParameters.map(dp =>
                  dp.id === data.id
                    ? {
                        ...dp,
                        parameter: data,
                        name: data.name,
                        text: data.name,
                      }
                    : dp,
                )
              : tile.properties.displayParameters,
            settingParameters: tile.properties.settingParameters.some(dp => dp.id === data.id)
              ? tile.properties.settingParameters.map(dp =>
                  dp.id === data.id
                    ? {
                        ...dp,
                        parameter: data,
                        name: data.name,
                        text: data.name,
                      }
                    : dp,
                )
              : tile.properties.settingParameters,
          },
        }
      : tile,
  );
};

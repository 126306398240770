import { eventEmitter, events } from '../components/tracking/AppInsights';

export const LOCAL_STORAGE_ERROR_EVENT = 'localStorage_critical_error';

export const getValueFromLocalStorage = key => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const setValueInLocalStorage = (key, value) => {
  if (value !== null) {
    // throwing an error here will be caught by the storage monitor
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const deleteValueFromLocalStorage = key => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const criticalKeys = [
  'ADMIN_USER_TYPE',
  'AZURE_B2C_POLICY_NAME',
  'USER_PRIVILEGES',
  'AZURE_B2C_TOKENS',
  'IS_AZURE_B2C_AUTH',
  'LAST_USED_SYSTEM',
  'LAST_USED_SYSTEM_BRAND',
  'mavenoid-embedded-visitor-id',
  'LAST_USED_LANGUAGE',
];

export const getLocalStorageWithoutCriticalKeys = () => {
  const filledStorage = {};

  const keys = Object.keys(localStorage);
  keys.forEach(key => {
    if (!criticalKeys.includes(key)) {
      filledStorage[key] = localStorage.getItem(key);
    }
  });

  return filledStorage;
};

export const handleQuotaExceededWithClearingStorage = error => {
  // Log the error and current state to AppInsights
  const errorLog = {
    error,
    exception: {
      error,
      message: JSON.stringify(getLocalStorageWithoutCriticalKeys()), // local storage snapshot,
    },
  };
  eventEmitter.emit(events.logError, errorLog);

  try {
    // Backup critical data first
    const backup = {};
    criticalKeys.forEach(key => {
      try {
        const value = localStorage.getItem(key);
        if (value) backup[key] = value;
      } catch (e) {
        // Ignore errors during backup
      }
    });

    clearLocalStorage();

    // Restore critical data
    Object.entries(backup).forEach(([key, value]) => {
      try {
        localStorage.setItem(key, value);
      } catch (e) {
        console.error(`Failed to restore critical key ${key}:`, e);
        throw e;
      }
    });
  } catch (e) {
    eventEmitter.emit(events.logError, e);
    console.error('Failed to handle quota exceeded situation:', e);

    // Display a persistent notification to the user
    emitStorageError(
      'critical',
      'Your browser Storage is full. Automated cleanup failed. Please log out and log back in to resolve the issue.',
    );
  }
};

export const emitStorageError = (level = 'warning', message = 'Storage issue detected') => {
  const event = new CustomEvent(LOCAL_STORAGE_ERROR_EVENT, {
    detail: {
      level, // 'warning' | 'critical'
      message,
      timestamp: Date.now(),
    },
  });

  window.dispatchEvent(event);
  console.error(`Storage Error [${level}]: ${message}`);
};

export const createStorageMonitor = () => {
  try {
    // Save reference to the original methods
    const originalSetItem = localStorage.setItem;
    const originalRemoveItem = localStorage.removeItem;
    const originalClear = localStorage.clear;

    // Override setItem method with proper error handling
    localStorage.setItem = function (key, value) {
      try {
        // Call original method - this will throw if quota is exceeded
        originalSetItem.call(this, key, value);
      } catch (error) {
        console.error('Error in localStorage.setItem:', error);

        if (
          error.name === 'QuotaExceededError' ||
          error.code === 22 || // Chrome's quota error code
          error.message?.includes('quota') ||
          error.message?.includes('storage') ||
          error.message?.includes('exceeded')
        ) {
          console.warn('🚨 localStorage quota exceeded! Performing cleanup...', getLocalStorageWithoutCriticalKeys());

          // Handle quota exceeded situation with our utility
          handleQuotaExceededWithClearingStorage(error);
        }

        // Re-throw the original error to ensure loops break
        throw error;
      }
    };

    localStorage.removeItem = function (key) {
      originalRemoveItem.call(this, key);
    };

    localStorage.clear = function () {
      originalClear.call(this);
    };

    // Restore original methods
    return () => {
      localStorage.setItem = originalSetItem;
      localStorage.removeItem = originalRemoveItem;
      localStorage.clear = originalClear;
    };
  } catch (e) {
    console.error('Failed to initialize localStorage monitoring:', e);
    // Return a no-op function as fallback
    return () => {};
  }
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { languageSelected } from '../../layout/LanguageSelector/actions';
import './base-legal-page.scss';
export function BaseLegalPage({ languageSelected, content, selectedLanguage, userInfo, history, match }) {
  const handleLocale = () => {
    const { locale } = match.params;

    const requestedLanguage = locale || 'en';

    // Remove locale from URL since it is not our pattern
    if (locale) {
      if (requestedLanguage !== selectedLanguage) {
        languageSelected(requestedLanguage, userInfo);
      }
      return history.push(`${match.url.slice(0, -3)}`);
    }

    languageSelected(selectedLanguage, userInfo);
  };

  useEffect(() => {
    handleLocale();
  }, [selectedLanguage]);

  return <div className="legal-container">{content}</div>;
}

BaseLegalPage.propTypes = {
  content: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string,
  userInfo: PropTypes.object,
  setLanguage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  languageSelected: PropTypes.func.isRequired,
};

export default connect(
  ({ language: { selectedLanguage }, app: { userInfo } }) => ({
    selectedLanguage,
    userInfo,
  }),
  {
    languageSelected,
  },
)(BaseLegalPage);

import { getValueFromLocalStorage, setValueInLocalStorage, deleteValueFromLocalStorage } from '../localStorage';

// FIXME: Make generic mechanism for storing charts
const HISTORY_CHART_STORE_KEYS = {
  first: 'HISTORY_CHART[1]',
  second: 'HISTORY_CHART[2]',
};

// TODO: Move this whole file under services
export const getFirstChartData = () => {
  return getValueFromLocalStorage(HISTORY_CHART_STORE_KEYS.first);
};

export const setFirstChartData = charts => {
  setValueInLocalStorage(HISTORY_CHART_STORE_KEYS.first, charts);
};

export const removeFirstChart = () => deleteValueFromLocalStorage(HISTORY_CHART_STORE_KEYS.first);

export const getSecondChartData = () => {
  return getValueFromLocalStorage(HISTORY_CHART_STORE_KEYS.second);
};

export const setSecondChartData = charts => {
  setValueInLocalStorage(HISTORY_CHART_STORE_KEYS.second, charts);
};

export const removeSecondChart = () => deleteValueFromLocalStorage(HISTORY_CHART_STORE_KEYS.second);

export const hasSecondChart = () => getValueFromLocalStorage(HISTORY_CHART_STORE_KEYS.second) !== null;

import { getValueFromLocalStorage, setValueInLocalStorage } from '../localStorage';

const lastUsedLanguageKey = 'LAST_USED_LANGUAGE';

export const set = language => {
  setValueInLocalStorage(lastUsedLanguageKey, language);
};

export const get = () => {
  return getValueFromLocalStorage(lastUsedLanguageKey);
};

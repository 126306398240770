import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getThirdPartyApps, removeAccessForThirdPartyApp } from './actions';
import { connect } from 'react-redux';

import './thirdPartyApps.scss';
import { DemoAccountVerifier } from '../../../../layout/DemoAccountVerifier';

const VISIBLE_SCOPES = ['READSYSTEM', 'WRITESYSTEM'];

const ThirdPartyItem = ({ item, onDelete, isDemoUser }) => (
  <div className="table--content">
    <div className="table--item">{item.clientName || item.clientId}</div>
    <div className="table--item">
      {item.scopes
        .filter(scope => VISIBLE_SCOPES.includes(scope))
        .map(scope => (
          <div key={scope}>
            <FormattedMessage
              id={'profile-settings.permission.scopes.' + scope.toLowerCase()}
              defaultMessage={
                scope.toLowerCase() === 'readsystem' ? 'Read data from your systems' : 'Make changes to your systems'
              }
            />
          </div>
        ))}
    </div>
    <div className="table--item">
      <DemoAccountVerifier
        position="top"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        tooltipStyle={{ left: '69px', bottom: '100%' }}
      >
        <button className="button-remove" role="button" disabled={isDemoUser} onClick={onDelete}>
          <i className="fa fa-times"></i>
        </button>
      </DemoAccountVerifier>
    </div>
  </div>
);

ThirdPartyItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool,
};

class ThirdPartyApps extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    isDemoUser: PropTypes.bool,
    thirdPartyApps: PropTypes.array.isRequired,
    getThirdPartyApps: PropTypes.func.isRequired,
    removeAccessForThirdPartyApp: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    if (this.props.userId) {
      await this.props.getThirdPartyApps(this.props.userId);
    }
  }

  async revokeAccess(clientId) {
    if (this.props.isDemoUser) return;
    await this.props.removeAccessForThirdPartyApp(this.props.userId, clientId);
  }

  render() {
    return (
      <div>
        <h1>
          <FormattedMessage id="label.permissions" defaultMessage="Permissions" />
        </h1>
        <div className="permission-description">
          <FormattedMessage
            id="profile-settings.permission.description"
            defaultMessage="You gave these sites and apps access to your myUplink account, including information that may be sensitive. Review and remove access for those that you no longer trust or use."
          />
        </div>
        <div className="table">
          <div className="table--header">
            <div className="table--item">
              <FormattedMessage id="label.third-party-apps" defaultMessage="Third-Party Apps" />
            </div>
            <div className="table--item">
              <FormattedMessage id="label.permission" defaultMessage="Permissions" />
            </div>
            <div className="table--item table--remove-headCell">
              <FormattedMessage id="label.remove" defaultMessage="Remove" />
            </div>
          </div>
          {this.props.thirdPartyApps.map(item => (
            <ThirdPartyItem
              item={item}
              key={item.clientId}
              onDelete={() => this.revokeAccess(item.clientId)}
              isDemoUser={this.props.isDemoUser}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    userId: state.app.userInfo.id,
    isDemoUser: state.app.userInfo.isDemo,
    thirdPartyApps: state.thirdPartyAppsTab.thirdPartyApps,
  }),
  {
    getThirdPartyApps,
    removeAccessForThirdPartyApp,
  },
)(ThirdPartyApps);

export const Brands = {
  AIRSITE: 'airsite',
  AIT: 'ait',
  BIAWAR: 'biawar',
  CETETHERM: 'cetetherm',
  CLIMATEMASTER: 'climatemaster',
  CONTURA: 'contura',
  CTA: 'cta',
  CTC: 'ctc',
  DZD: 'dzd',
  DANDELION: 'dandelion',
  ENERTECH: 'enertech',
  HOIAX: 'hoiax',
  IEC: 'iec',
  JAMA: 'jama',
  JASPI: 'jaspi',
  METROTHERM: 'metrotherm',
  MHI: 'mhi',
  MYUPLINK: 'myuplink',
  NIBE: 'nibe',
  NIBEF: 'nibef',
  NOVELAN: 'novelan',
  ROTH: 'roth',
  TEMPEFF: 'tempeff',
  TIKI: 'tiki',
  ECOSMARTSOLUTION: 'ecosmartsolution',
  EMULATEENERGY: 'emulateenergy',
  ENODE: 'enode',
  EQ3: 'eq3',
  FORTUM: 'fortum',
  GREENELY: 'greenely',
  KAPACITY: 'kapacity',
  NEOGRID: 'neogrid',
  NOVEN: 'noven',
  PODERO: 'podero',
  PRAGMATIC: 'pragmatic',
  TIBBER: 'tibber',
  SOLARMANAGER: 'solarmanager',
  LKSYSTEMS: 'lksystems',
  UPONOR: 'uponor',
};

const hideBrandForProdAndStageEnv = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';

export const brandsAndUrl = [
  {
    brand: Brands.AIT,
    url: '/assets/brands/ait/ait-logo.svg',
    companyUrl: 'https://www.alpha-innotec.com/',
    hide: false,
  },
  {
    brand: Brands.BIAWAR,
    url: '/assets/brands/biawar/biawar-logo.svg',
    companyUrl: 'https://www.biawar.com.pl/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.CETETHERM,
    url: '/assets/brands/cetetherm/cetetherm-logo.svg',
    companyUrl: 'https://www.cetetherm.com/',
    hide: false,
  },
  {
    brand: Brands.CLIMATEMASTER,
    url: '/assets/brands/climatemaster/climatemaster-logo.png',
    companyUrl: 'https://www.climatemaster.com/',
    hide: false,
  },
  {
    brand: Brands.CONTURA,
    url: '/assets/brands/contura/contura-logo.jpg',
    companyUrl: 'https://www.contura.eu/',
    hide: false,
  },
  { brand: Brands.CTA, url: '/assets/brands/cta/cta-logo.png', companyUrl: 'https://www.cta.ch', hide: false },
  { brand: Brands.CTC, url: '/assets/brands/ctc/ctc-logo.png', companyUrl: 'https://ctc-heating.com/', hide: false },
  {
    brand: Brands.DZD,
    url: '/assets/brands/dzd/dzd-logo.svg',
    companyUrl: 'https://dzd.cz/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.DANDELION,
    url: '/assets/brands/dandelion/dandelion-logo.svg',
    companyUrl: 'https://dandelionenergy.com/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.ENERTECH,
    url: '/assets/brands/enertech/enertech-logo.png',
    companyUrl: 'https://enertechusa.com/',
    hide: false,
  },
  { brand: Brands.HOIAX, url: '/assets/brands/hoiax/hoiax-logo.svg', companyUrl: 'https://www.hoiax.no/', hide: false },
  {
    brand: Brands.IEC,
    url: '/assets/brands/iec/iec-logo.svg',
    companyUrl: 'https://iec-okc.com/',
    hide: false,
  },
  {
    brand: Brands.JAMA,
    url: '/assets/brands/jama/jama-logo.svg',
    companyUrl: 'https://jaspi.fi/',
    hide: false,
  },
  {
    brand: Brands.JASPI,
    url: '/assets/brands/jaspi/jaspi-logo.svg',
    companyUrl: 'https://jaspi.fi/',
    hide: false,
  },
  {
    brand: Brands.METROTHERM,
    url: '/assets/brands/metrotherm/metrotherm-logo.svg',
    companyUrl: 'https://www.metrotherm.dk/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.MHI,
    url: '../../../../assets/brands/mhi/mhi-logo.svg',
    companyUrl: 'https://www.mhi.com',
    hide: hideBrandForProdAndStageEnv,
  },
  { brand: Brands.NIBE, url: '/assets/brands/nibe/nibe-logo.png', companyUrl: 'https://www.nibe.eu/', hide: false },
  {
    brand: Brands.AIRSITE,
    url: '/assets/brands/airsite/airsite-logo.svg',
    companyUrl: 'http://www.nibeairsite.com/sv/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.NOVELAN,
    url: '/assets/brands/novelan/novelan-logo.svg',
    companyUrl: 'https://www.novelan.com/',
    hide: false,
  },
  {
    brand: Brands.ROTH,
    url: '/assets/brands/roth/roth-logo.svg',
    companyUrl: 'https://www.roth-industries.com/',
    hide: false,
  },
  {
    brand: Brands.TEMPEFF,
    url: '/assets/brands/tempeff/tempeff-logo.svg',
    companyUrl: 'https://www.tempeff.com/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.TIKI,
    url: '/assets/brands/tiki/tiki-logo.svg',
    companyUrl: 'https://www.tikigroup.eu/',
    hide: hideBrandForProdAndStageEnv,
  },
];

export const compatibleProviders = [
  {
    brand: Brands.ECOSMARTSOLUTION,
    url: '/assets/brands/ecosmart/ecosmart-logo.svg',
    companyUrl: 'https://ecosmartsolution.com/',
  },
  {
    brand: Brands.EMULATEENERGY,
    url: '/assets/brands/emulate/emulate-logo.png',
    companyUrl: 'https://emulate.energy/',
  },
  {
    brand: Brands.ENODE,
    url: '/assets/brands/enode/enode-logo.svg',
    companyUrl: 'https://enode.com/',
  },
  {
    brand: Brands.EQ3,
    url: '/assets/brands/eq3/eq3-logo.svg',
    companyUrl: 'https://www.eq-3.de/',
  },
  {
    brand: Brands.FORTUM,
    url: '/assets/brands/fortum/fortum-logo.svg',
    companyUrl: 'https://www.fortum.se/',
  },
  {
    brand: Brands.GREENELY,
    url: '/assets/brands/greenely/greenely-logo.svg',
    companyUrl: 'https://greenely.com/',
  },
  {
    brand: Brands.KAPACITY,
    url: '/assets/brands/kapacity/kapacity-logo.png',
    companyUrl: 'https://www.kapacity.io/',
  },
  {
    brand: Brands.NEOGRID,
    url: '/assets/brands/neogrid/neogrid-logo.png',
    companyUrl: 'https://neogrid.dk/',
  },
  {
    brand: Brands.NOVEN,
    url: '/assets/brands/noven/noven-logo.svg',
    companyUrl: 'https://noven.be/',
  },
  {
    brand: Brands.PODERO,
    url: '/assets/brands/podero/podero-logo.png',
    companyUrl: 'https://www.podero.com/',
  },
  {
    brand: Brands.PRAGMATIC,
    url: '/assets/brands/pragmatic/pragmatic-logo.svg',
    companyUrl: 'https://pragmaticenergy.co.uk/',
  },
  {
    brand: Brands.TIBBER,
    url: '/assets/brands/tibber/tibber-logo.svg',
    companyUrl: 'https://tibber.com/',
  },
  {
    brand: Brands.SOLARMANAGER,
    url: '/assets/brands/solarmanager/solarmanager-logo.svg',
    companyUrl: 'https://www.solarmanager.ch/',
  },
  {
    brand: Brands.LKSYSTEMS,
    url: '/assets/brands/lksystems/lksystems-logo.svg',
    companyUrl: 'https://www.lksystems.se/',
  },
  {
    brand: Brands.UPONOR,
    url: '/assets/brands/uponor/uponor-logo.png',
    companyUrl: 'https://www.uponor.com/',
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Checkbox } from 'jpi-cloud-web-ui-components';
import { CSVDownloader } from 'react-papaparse';
import _ from 'lodash';

import downloadCloud from '../../../../../assets/img/download-cloud.svg';

import { events, groups } from '../../../../../services/clarity/events';
import { setupTracker } from '../../../../../services/clarity';
import { DemoAccountVerifier } from '../../../../layout/DemoAccountVerifier';
import { useUserInfo } from '../../../../../hooks';

/**
 * MS Clarity tracker
 * @param string event
 * @void
 */
const trackEvent = setupTracker(groups.HISTORY);

// TODO: Add test
const Controls = ({
  fullScreen,
  setFullScreen,
  intl,
  data,
  setZoom,
  selectedZoomPoints,
  isParameterSelected,
  numberOfChart,
  zoomInRange,
  showPoints,
  setPoints,
}) => {
  const { isDemo } = useUserInfo();
  const nonExportableParameters = [
    { name: 'Current Price', code: '-32002' },
    { name: 'El. Price', code: '12623' },
  ];

  const filteredData = data.filter(item => !nonExportableParameters.some(param => item.Parameter.includes(param.code)));

  let csv = '';

  const timedata = [...new Set(filteredData.flatMap(({ Time }) => Time))].sort((a, b) => (a > b ? 1 : -1));
  const valuedata = [];

  for (const key in filteredData) {
    if (filteredData.hasOwnProperty(key)) {
      const times = filteredData[key].Time;
      const value = filteredData[key].Value;

      const values = timedata.map((time, i) =>
        !times.includes(time) && (i === 0 || !times.includes(time[i - 1])) ? 'NULL;' : value[times.indexOf(time)],
      );

      values.map((val, i) =>
        val === 'NULL;' && val - 1 != 'NULL;' && i !== 0 ? (values[i] = values[i - 1]) : values[i],
      );
      valuedata.push(values);
    }
  }

  const csvdata = valuedata.length > 0 ? valuedata.reduce((a, b) => a.map((v, i) => v + b[i])) : [];

  const parameterdata = timedata
    .map((a, b) => `${a}${csvdata[b]}\n`)
    .toString()
    .replace(/,/g, '');

  const header = filteredData
    .map(({ Parameter }) => Parameter)
    .toString()
    .replace(/,/g, '')
    .replace(/^/, 'timestamp;');
  csv += header + '\n' + parameterdata;

  /**
   * toggleZoom [true -> in | false -> out]
   * @param boolean toggleZoom
   * @returns Function
   */
  const prepareZoom =
    (toggleZoom = false) =>
    () => {
      trackEvent(toggleZoom ? events.CHART_ZOOM_IN : events.CHART_ZOOM_OUT);
      setZoom(toggleZoom);
    };

  const togglePoints = e => {
    setPoints(e?.target?.checked);
    trackEvent(events.CHART_POINTS_SHOW);
  };

  const toggleFullScreen = () => {
    if (typeof fullScreen !== 'string' || typeof setFullScreen !== 'function') return;
    if (fullScreen !== '') trackEvent(events.CHART_EXPAND);
    const parameterFullScreen = fullScreen.length > 0 ? '' : numberOfChart ?? '';
    setFullScreen({ fullScreen: parameterFullScreen });
  };

  const trackExport = () => {
    trackEvent(events.CHART_EXPORT);
  };

  return (
    <div className="chart-control-group">
      <div>
        <Checkbox
          id={'points' + Math.floor(Math.random() * 5)}
          checked={showPoints === numberOfChart}
          onChange={togglePoints}
        />
        <p className="controls-title">
          <FormattedMessage id={'history.points'} defaultMessage="Show Points" />
        </p>
      </div>
      <div>
        <Button
          type="button"
          className="chart-control-group__button export--icon"
          onClick={prepareZoom(true)}
          disabled={!isParameterSelected || zoomInRange === 0}
        >
          <i className="fas fa-plus"></i>
        </Button>
        <p className="controls-title">
          <FormattedMessage id={'history.controls.Zoom-in'} defaultMessage="Zoom in" />
        </p>
      </div>
      <div>
        <Button
          type="button"
          className="chart-control-group__button export--icon"
          onClick={prepareZoom()}
          disabled={!selectedZoomPoints || zoomInRange === 10}
        >
          <i className="fas fa-minus"></i>
        </Button>
        <p className="controls-title">
          <FormattedMessage id={'history.controls.Zoom-out'} defaultMessage="Zoom out" />
        </p>
      </div>
      <div>
        <DemoAccountVerifier position="top" tooltipStyle={{ right: '-80px', left: 'unset', bottom: '110%' }}>
          <Button
            type="button"
            onClick={trackExport}
            className="chart-control-group__button export--icon"
            disabled={isDemo || !isParameterSelected}
          >
            <CSVDownloader
              id="history.export"
              data={parameterdata.length > 0 ? csv : '\n'}
              header={true}
              newline={'\n'}
              bom={true}
              filename={'history-export'}
            >
              <img src={downloadCloud} className="icon"></img>
            </CSVDownloader>
          </Button>
          <p className="controls-title">
            <FormattedMessage id={'history.controls.export'} defaultMessage="Export" />
          </p>
        </DemoAccountVerifier>
      </div>
      <div>
        <Button
          className="chart-control-group__button button--icon"
          onClick={toggleFullScreen}
          title={intl.formatMessage({
            id: fullScreen !== numberOfChart ? 'history.full-screen' : 'history.restore',
          })}
          type="button"
        >
          <FontAwesomeIcon icon={fullScreen === numberOfChart ? faCompress : faExpand} size="2x" />
        </Button>
        <p className="controls-title">
          <FormattedMessage
            id={fullScreen === numberOfChart ? 'history.controls.collapse' : 'history.controls.expand'}
            defaultMessage="Expand"
          />
        </p>
      </div>
    </div>
  );
};

Controls.propTypes = {
  showPoints: PropTypes.bool,
  setPoints: PropTypes.func,
  setZoom: PropTypes.func,
  resetZoom: PropTypes.func,
  fullScreen: PropTypes.string,
  setFullScreen: PropTypes.func,
  intl: PropTypes.object,
  data: PropTypes.array,
  numberOfChart: PropTypes.string,
  isParameterSelected: PropTypes.number,
  selectedZoomPoints: PropTypes.object,
  zoomInRange: PropTypes.number,
};

export default React.memo(injectIntl(Controls), (prev, next) => {
  const isShowPointsUpdated = prev.showPoints !== next.showPoints;
  const isFullScreenUpdated = prev.fullScreen !== next.fullScreen;
  const isAggregationMethodUpdated = prev.aggregationMethod !== next.aggregationMethod;
  const isNumberOfChartUpdated = prev.numberOfChart !== next.numberOfChart;
  const isZoomInRangeUpdated = prev.zoomInRange !== next.zoomInRange;
  const isParameterSelectedUpdated = prev.isParameterSelected !== next.isParameterSelected;
  const isDataUpdated = !_.isEqual(prev.data, next.data);
  return !(
    isShowPointsUpdated ||
    isFullScreenUpdated ||
    isAggregationMethodUpdated ||
    isNumberOfChartUpdated ||
    isZoomInRangeUpdated ||
    isParameterSelectedUpdated ||
    isDataUpdated
  );
});

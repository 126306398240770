import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Content from './components/Content';
import Header from './components/Header';
import Slideshow from '../Slideshow';
import Footer from '../Footer';
import myUplinkLogo from '../../../assets/img/myUplink_logo.png';

import { languageSelected } from '../LanguageSelector/actions';
import { getLinkSet } from './blockPassLinks';

import './signed-out-layout.scss';

import mockImage from '../../../assets/img/mock.png';

import remoteConnection from '../../../assets/img/remote-connection.png';
import feelComfortable from '../../../assets/img/feel-comfortable.png';
import saveEnergy from '../../../assets/img/save-energy.png';
import WorksWithBrands from '../Slideshow/CompanyImages/WorksWithBrands';
import { Logo } from '../../Logo';
import CustomLanguageSelector from './components/CustomLanguageSelector/CustomLanguageSelector';
import { Link } from '../../Link';
import { get } from '../../../api/language';

const SignOutLayout = ({ component: Component, url, onlanguageChange, languageSelected, intl, status, ...rest }) => {
  const slides = [
    {
      image: mockImage,
      title: 'slide.title.default',
      text: 'slide.text.default',
      alt: 'Phone with NIBE app',
    },
    {
      image: remoteConnection,
      title: 'slide.title.remote-connection',
      text: 'slide.text.remote-connection',
      alt: 'Girl is looking to the app',
    },
    {
      image: feelComfortable,
      title: 'slide.title.feel-сomfortable',
      text: 'slide.text.feel-сomfortable',
      alt: 'Man is drinking coffee at home',
    },
    {
      image: saveEnergy,
      title: 'slide.title.save-energy',
      text: 'slide.text.save-energy',
      alt: 'Man is trying to touch the lamp',
    },
    {
      imageComponent: <WorksWithBrands />,
      title: 'slide.title.works-with',
      text: 'slide.text.works-with',
      alt: 'Company logo with whom we work',
      buttonText: 'slide.button.works-with',
    },
  ];

  useEffect(() => {
    if (languageSelected !== get('LAST_USED_LANGUAGE')) {
      onlanguageChange(get('LAST_USED_LANGUAGE'), { id: null });
    }
  }, []);

  const commonUrlPath = 'legal';
  const isLegalPage = rest.path.includes(commonUrlPath);

  return (
    <div className="wrapper">
      <div id="skiplink">
        <div className="page-centered">
          {getLinkSet(rest.path, intl).map((item, index) => (
            <a key={`item.href--${index}`} href={item.href}>
              {item.text}
            </a>
          ))}
        </div>
      </div>
      {status && status.statusCode && status.statusCode.value !== 'status-0' && (
        <div className="status-banner">{status.statusText}</div>
      )}

      <div className={`welcome-page ${!isLegalPage ? 'fullHeight' : 'aboutApp-page-height'}`}>
        <main role="main">
          {!isLegalPage ? (
            <div className="page-centered welcome-content">
              <div className="logo-wrapper">
                <Logo />
              </div>
              <Content
                id="slider"
                tagName="section"
                className="wide fullPage slider-section"
                isUpAlignment={true}
                aria-label="App features slides"
              >
                <Slideshow slides={slides} />
              </Content>
              <Content
                tagName="section"
                className="narrow fullPage login-section login-section-accessible-background"
                isUpAlignment={true}
              >
                <Header
                  url={url}
                  showLangShortcodes={false}
                  showLangIcon={true}
                  showLogo={false}
                  className="lang-desk"
                  isLoginHeader={true}
                />
                <Route {...rest} render={matchProps => <Component {...matchProps} />} />
              </Content>{' '}
            </div>
          ) : (
            <div className="aboutApp-page">
              <div className="aboutApp-page-header-wrapper">
                <div className="aboutApp-page-header">
                  <Link className="header-logo" to={'/'} tabIndex={0}>
                    <img src={myUplinkLogo} alt="This is myUplink logo" />
                  </Link>
                  <CustomLanguageSelector showShortCodes={false} showIcon={false} />
                </div>
              </div>
              <Content tagName="section" className="aboutApp-page-content" isUpAlignment>
                <Route {...rest} render={matchProps => <Component {...matchProps} />} />
              </Content>
            </div>
          )}
        </main>
        <Footer
          isPremiumButtonVisible={false}
          hasSystems={true}
          isSmartGuideAvailable={true}
          isWelcome={true}
          languageSelected={languageSelected}
        />
      </div>
    </div>
  );
};

SignOutLayout.propTypes = {
  intl: PropTypes.object.isRequired,
  url: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    languageSelected: state.language.selectedLanguage,
    url: state.app.url,
    status: state.app.status,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onlanguageChange: (language, userInfo) => {
      dispatch(languageSelected(language, userInfo));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SignOutLayout));

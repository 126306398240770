import React from 'react';
import PropTypes from 'prop-types';

const MenuBreadcrumbs = ({ menuHistory, onNavigate }) => (
  <nav className="menu-breadcrumbs">
    {menuHistory.map((item, index) => (
      <span key={item.id} className="breadcrumb-item">
        <a onClick={() => onNavigate(item.id)}>{item.name}</a>
        {index < menuHistory.length - 1 && ' / '}
      </span>
    ))}
  </nav>
);

MenuBreadcrumbs.propTypes = {
  menuHistory: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default MenuBreadcrumbs;

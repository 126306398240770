import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MessageInboxHeader from './components/MessageInboxHeader';
import Tabs from '../../layout/Tabs';
import AlarmsList from './components/AlarmsList';
import { getInboxAlarms, getArchivedAlarms } from './actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import './alarms.scss';
import { Spinner } from 'jpi-cloud-web-ui-components';
import { inboxTab, archivedTab, tabs } from './constants';
import { USER_SECURITY_LEVELS } from '../SystemProfile/components/SecurityTab/costants';
import _ from 'lodash';

const PAGE_SIZE = 10;
const DELAY = 150;

class Alarms extends Component {
  static propTypes = {
    alarms: PropTypes.object.isRequired,
    getInboxAlarms: PropTypes.func.isRequired,
    getArchivedAlarms: PropTypes.func.isRequired,
    systems: PropTypes.array.isRequired,
    devices: PropTypes.array.isRequired,
    userInfo: PropTypes.object,
    selectedSystem: PropTypes.object,
  };

  state = {
    activeTab: inboxTab,
    loading: false,
  };

  async componentDidMount() {
    this.trackScrollDebounce = _.debounce(this.trackScrolling, DELAY);
    document.addEventListener('scroll', this.trackScrollDebounce);

    if (this.props.devices.length > 0) {
      const deviceId = this.props.devices[0].id;
      await this.props.getInboxAlarms(deviceId, 1, true);
      await this.props.getArchivedAlarms(deviceId, 1, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrollDebounce);
  }

  async componentDidUpdate(prevProps) {
    if (
      (this.props.devices.length > 0 && prevProps.devices.length === 0) ||
      (this.props.devices.length > 0 &&
        prevProps.devices.length > 0 &&
        this.props.devices[0].id !== prevProps.devices[0].id)
    ) {
      const deviceId = this.props.devices[0].id;
      await this.props.getInboxAlarms(deviceId);
      await this.props.getArchivedAlarms(deviceId);
    }
  }

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('alarms-container');
    if (wrappedElement && this.isBottom(wrappedElement) && !this.state.loading) {
      const deviceId = this.props.devices[0].id;
      if (
        this.state.activeTab === inboxTab &&
        this.props.alarms.inbox.totalCount > this.props.alarms.inbox.alarms.length &&
        this.props.alarms.inbox.totalCount > PAGE_SIZE * this.props.alarms.inbox.page
      ) {
        this.setState({ loading: true });
        await this.props.getInboxAlarms(deviceId, this.props.alarms.inbox.page + 1, false, PAGE_SIZE);
      }
      if (
        this.state.activeTab === archivedTab &&
        this.props.alarms.archived.totalCount > this.props.alarms.archived.alarms.length &&
        this.props.alarms.archived.totalCount > PAGE_SIZE * this.props.alarms.archived.page
      ) {
        this.setState({ loading: true });
        await this.props.getArchivedAlarms(deviceId, this.props.alarms.archived.page + 1, false, PAGE_SIZE);
      }

      this.setState({ loading: false });
    }
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  onClickTabItem = async tab => {
    this.setState({ activeTab: tab });
  };

  getAlarms = (tab, alarms) => {
    if (tab === inboxTab) {
      return alarms.inbox;
    }
    if (tab === archivedTab) {
      return alarms.archived;
    }

    return {
      alarms: [],
    };
  };

  render() {
    const { loading } = this.state;
    const { selectedSystem } = this.props;

    const alarms = this.getAlarms(this.state.activeTab, this.props.alarms);
    const hasSystems = this.props.systems && this.props.systems.length > 0;

    const alarmItems = alarms && hasSystems ? alarms.alarms : [];
    const userIsViewer = selectedSystem && selectedSystem.userRole === USER_SECURITY_LEVELS.viewer;

    const uniqueAlarms = Array.from(new Map(alarmItems.map(alarm => [alarm.id, alarm])).values());

    return (
      <div className="page-content">
        <div className="row">
          <div className="alarms-wrapper">
            <MessageInboxHeader />
            <Tabs labels={tabs} onClickTabItem={this.onClickTabItem} activeTab={this.state.activeTab}>
              {alarmItems.length > 0 ? (
                <div id="alarms-container">
                  <AlarmsList type={this.state.activeTab} items={uniqueAlarms} userIsViewer={userIsViewer} />
                  {loading && <Spinner dark />}
                </div>
              ) : (
                <p className="alarms-notification">
                  <FormattedMessage id="alarms.no-alarms-message" defaultMessage="There are no notifications." />
                </p>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ app: { selectedSystem, userInfo, systems }, devices: { devices }, alarms: { alarms } }) => ({
    alarms,
    selectedSystem,
    userInfo,
    systems,
    devices,
  }),
  {
    getInboxAlarms,
    getArchivedAlarms,
  },
)(injectIntl(Alarms));

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { LOCAL_STORAGE_ERROR_EVENT } from '../../localStorage';

import './StorageErrorNotification.scss';

const StorageErrorNotification = ({ userLoggedIn }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleStorageError = event => {
      setError(event.detail);
    };

    window.addEventListener(LOCAL_STORAGE_ERROR_EVENT, handleStorageError);

    return () => {
      window.removeEventListener(LOCAL_STORAGE_ERROR_EVENT, handleStorageError);
    };
  }, []);

  if (!error || !userLoggedIn) return null;

  const handleDismiss = () => setError(null);

  const notificationClass = `storage-error-notification ${error.level || 'warning'}`;

  return (
    <div className={notificationClass}>
      <div className="storage-error-notification__content">
        <div className="storage-error-notification__icon">⚠️</div>
        <div className="storage-error-notification__message">
          <FormattedMessage
            id="storage.error.default"
            defaultMessage="A browser storage error occurred. Please log out and log back in."
          />
        </div>
      </div>
      <button className="storage-error-notification__action" onClick={handleDismiss}>
        <FormattedMessage id="common.dismiss" defaultMessage="Dismiss" />
      </button>
    </div>
  );
};

StorageErrorNotification.propTypes = {
  userLoggedIn: PropTypes.bool,
};

StorageErrorNotification.defaultProps = {
  userLoggedIn: false,
};

export default StorageErrorNotification;

import React, { useState } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Button, Input } from 'jpi-cloud-web-ui-components';

import { NotificationManager } from 'react-notifications';
import { getAzureB2CChangeEmailLink } from '../../../../../../api/azureB2C';
import { DemoAccountVerifier } from '../../../../../layout/DemoAccountVerifier';

const EmailSection = ({ userInfo, selectedLanguage }) => {
  const [loading, setLoading] = useState(false);

  const handleEmailChange = async () => {
    const returnUri = window.location.origin + '/profile-settings/security';

    setLoading(true);

    try {
      const mfaChangeEmailUrl = await getAzureB2CChangeEmailLink(returnUri, selectedLanguage);
      window.location.assign(mfaChangeEmailUrl);
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage
          id="errors.unexpected-support"
          defaultMessage="Unexpected error occurred, please contact support."
        />,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p>
        <FormattedMessage id="common.email" defaultMessage="Email" />
      </p>
      <div className="email-wrapper">
        <Input type="text" name="email" value={userInfo.email} disabled />
      </div>
      <div className="button-wrapper">
        <DemoAccountVerifier position="top" tooltipStyle={{ left: 0, bottom: '115%' }}>
          <Button
            className="button--primary"
            onClick={handleEmailChange}
            type="button"
            disabled={userInfo.isDemo || loading}
          >
            <FormattedMessage id="button.adb2c.localaccountpasswordreset.ver_but_edit" defaultMessage="Change e-mail" />
          </Button>
        </DemoAccountVerifier>
      </div>
    </>
  );
};

EmailSection.propTypes = {
  userInfo: PropTypes.object,
  selectedLanguage: PropTypes.string,
};

const stateProps = ({ app: { userInfo }, language: { selectedLanguage } }) => ({ userInfo, selectedLanguage });

export default connect(stateProps)(EmailSection);

import { useUserInfo } from '../../../hooks';
import React from 'react';
import PropTypes from 'prop-types';
import PremiumFeatureTooltip from '../PremiumFeatureTooltip';
import './demo-account-verifier.scss';

const DemoAccountVerifier = ({ position = 'top', children, style, tooltipStyle }) => {
  const { isDemo } = useUserInfo();

  if (!isDemo) return <div style={style}>{children}</div>;

  let positionDefault = 'top';
  if (position === 'top' || position === 'bottom' || position === 'left' || position === 'right') {
    positionDefault = position;
  }

  return (
    <div className={`demo-verifier-wrapper ${positionDefault}`} style={style}>
      <PremiumFeatureTooltip premiumFeatureType={'permissionAccess'} style={tooltipStyle} />
      <div className="demo-verifier-wrapper-content">{children}</div>
    </div>
  );
};

DemoAccountVerifier.propTypes = {
  position: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  tooltipStyle: PropTypes.object,
};

export default DemoAccountVerifier;

import uuid from 'uuid';

import * as tileApi from '../../../api/tiles';
import * as api from '../../../api';
import moment from 'moment';
import { ELECTRICAL_PRICE, ELECTRICAL_PRICE_TILE } from './TileTypes';

export const GET_TILE_LIST = 'tileManager/GET_TILE_LIST';
export const GET_TILE_LIST_SUCCEEDED = 'tileManager/GET_TILE_LIST_SUCCEEDED';
export const GET_ELECTRICAL_PRICE_TILE_SUCCEEDED = 'tileManager/GET_ELECTRICAL_PRICE_TILE_SUCCEEDED';
export const GET_TILE_LIST_FAILED = 'tileManager/GET_TILE_LIST_FAILED';

// TODO: Completely rework this so none of the actions are state-dependant.
// Current approach is harder to test, harder to debug and less readable,
// compared to sets of pure functions used in most of other components.
const overwriteAfterDispatch = action => async (dispatch, getState) => {
  dispatch(action);

  const state = getState();

  const { tileManager, app } = state;
  const { tiles } = tileManager;
  const { selectedSystem, userInfo } = app;
  await tileApi.write(tiles, selectedSystem.id, userInfo.id);
};

export const getTiles = systemId => async (dispatch, getState) => {
  dispatch({ type: GET_TILE_LIST });

  const { app } = getState();
  const response = await tileApi.get(systemId, app.userInfo ? app.userInfo.id : null);

  if (response.status === 200) {
    dispatch({ type: GET_TILE_LIST_SUCCEEDED, data: response.data });
    return response.data;
  }
  dispatch({ type: GET_TILE_LIST_FAILED, data: [] });

  return [];
};

export const GET_PREPOPULATION_STATUS = 'tileManager/GET_PREPOPULATION_STATUS';
export const GET_PREPOPULATION_STATUS_SUCCEEDED = 'tileManager/GET_PREPOPULATION_STATUS_SUCCEEDED';
export const GET_PREPOPULATION_STATUS_FAILED = 'tileManager/GET_PREPOPULATION_STATUS_FAILED';
export const getPrepopulationStatus = (userId, systemId) => async dispatch => {
  dispatch({ type: GET_PREPOPULATION_STATUS, systemId });

  const response = await tileApi.getPrepopulationStatus(userId, systemId);

  if (response.status === 200) {
    dispatch({ type: GET_PREPOPULATION_STATUS_SUCCEEDED, data: response.data });
    return true;
  }
  dispatch({ type: GET_PREPOPULATION_STATUS_FAILED, data: {} });

  return false;
};

export const REMOVE_SYSTEM_TILES = 'tileManager/REMOVE_SYSTEM_TILES';
export const REMOVE_SYSTEM_TILES_SUCCEEDED = 'tileManager/REMOVE_SYSTEM_TILES_SUCCEEDED';
export const REMOVE_SYSTEM_TILES_FAILED = 'tileManager/REMOVE_SYSTEM_TILES_FAILED';
export const removeSystemTiles = (systemId, userId) => async dispatch => {
  dispatch({ type: REMOVE_SYSTEM_TILES });

  const response = await tileApi.remove(systemId, userId);

  if (response.status === 200) {
    dispatch({ type: REMOVE_SYSTEM_TILES_SUCCEEDED });
    return true;
  }

  dispatch({ type: REMOVE_SYSTEM_TILES_FAILED });
  return false;
};

export const REMOVE_TILES_BY_DEVICE = 'tileManager/REMOVE_TILES_BY_DEVICE';
export const REMOVE_TILES_BY_DEVICE_SUCCEEDED = 'tileManager/REMOVE_TILES_BY_DEVICE_SUCCEEDED';
export const REMOVE_TILES_BY_DEVICE_FAILED = 'tileManager/REMOVE_TILES_BY_DEVICE_FAILED';
export const removeTilesByDevice = (systemId, userId, deviceId) => async dispatch => {
  dispatch({ type: REMOVE_TILES_BY_DEVICE });

  const response = await tileApi.removeByDevice(systemId, userId, deviceId);

  if (response.status === 200) {
    dispatch({ type: REMOVE_TILES_BY_DEVICE_SUCCEEDED, tiles: response.tiles });
    return true;
  }

  dispatch({ type: REMOVE_TILES_BY_DEVICE_FAILED });
  return false;
};

export const getIsTileRefreshNeeded = (userId, systemId) => async () => {
  const lastRefreshed = await tileApi.getLastRefreshed(userId, systemId);
  if (lastRefreshed.status === 200 && moment(lastRefreshed.data).isBefore(moment().utc().subtract(1, 'days'))) {
    return true;
  }
  return false;
};

export const refreshTileParameters = (groupId, tileType, tileNameTextId) => async () => {
  const response = await api.getTileParameters(groupId, tileType, tileNameTextId);
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const refreshElectricalTileParameters = groupId => async dispatch => {
  const response = await api.getTileParameters(groupId, ELECTRICAL_PRICE, ELECTRICAL_PRICE_TILE);
  if (response.status === 200) {
    dispatch({ type: GET_ELECTRICAL_PRICE_TILE_SUCCEEDED, data: response.data[0].parameter });
    return response.data;
  }
  return [];
};

export const refreshTileTypes = systemId => async () => {
  const response = await api.getGroupTileTypes(systemId);
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const setTilesLastRefreshed = (userId, systemId) => async () => {
  await tileApi.setLastRefreshed(userId, systemId);
  return true;
};

export const SET_SYSTEM_AS_PREPOPULATED = 'tileManager/SET_SYSTEM_AS_PREPOPULATED';
export const setSystemAsPrepopulated = (userId, systemId) => async dispatch => {
  dispatch({ type: SET_SYSTEM_AS_PREPOPULATED });
  await tileApi.setSystemAsPrepopulated(userId, systemId);
};

export const UNSET_SYSTEM_AS_PREPOPULATED = 'tileManager/UNSET_SYSTEM_AS_PREPOPULATED';
export const unsetSystemAsPrepopulated = (userId, systemId) => async dispatch => {
  dispatch({ type: UNSET_SYSTEM_AS_PREPOPULATED });
  await tileApi.unsetSystemAsPrepopulated(userId, systemId);
};

export const EXPAND_TILE = 'tileManager/EXPAND_TILE';
export const expandTile = tile => ({ type: EXPAND_TILE, tile });

export const MINIMIZE_TILE = 'tileManager/MINIMIZE_TILE';
export const minimizeTile = tile => ({ type: MINIMIZE_TILE, tile });

export const MOVE_TILE = 'tileManager/MOVE_TILE';
export const moveTile = (tile, toIndex) => overwriteAfterDispatch({ type: MOVE_TILE, tile, toIndex });

export const ADD_TILE = 'tileManager/ADD_TILE';
export const addTile = tile => overwriteAfterDispatch({ type: ADD_TILE, tile: { ...tile, id: uuid() } });

export const REMOVE_TILE = 'tileManager/REMOVE_TILE';
export const removeTile = tile => overwriteAfterDispatch({ type: REMOVE_TILE, tile });

export const EDIT_TILE = 'tileManager/EDIT_TILE';
export const editTile = tile => overwriteAfterDispatch({ type: EDIT_TILE, tile });

export const EDIT_TILE_SETTING = 'tileManager/EDIT_TILE_SETTING';
export const editTileSetting = (deviceId, parameterId, value, unit) => async dispatch => {
  dispatch({ type: EDIT_TILE_SETTING, deviceId, parameterId, value, unit });
  await api.patchTileSetting(deviceId, parameterId, value, unit);
};

export const EDIT_TILE_RAW_SETTING = 'tileManager/EDIT_TILE_RAW_SETTING';
export const editTileRawSetting = (deviceId, parameterId, value, unit) => async dispatch => {
  dispatch({ type: EDIT_TILE_RAW_SETTING });
  await api.patchTileRawSetting(deviceId, parameterId, value, unit);
};

export const GET_WEATHER_TILE_DATA_REQUEST = 'tileManager/GET_WEATHER_TILE_DATA_REQUEST';
export const GET_WEATHER_TILE_DATA_RESPONSE = 'tileManager/GET_WEATHER_TILE_DATA_RESPONSE';
export const getWeatherTileData = groupId => async dispatch => {
  if (groupId) {
    dispatch({ type: GET_WEATHER_TILE_DATA_REQUEST });
    await api
      .getWeatherTileData(groupId)
      .then(response => {
        dispatch({ type: GET_WEATHER_TILE_DATA_RESPONSE, weatherData: response.data });
      })
      .catch(error => {
        if (error.response.status === 404) {
          dispatch({ type: GET_WEATHER_TILE_DATA_RESPONSE, weatherData: {} });
          return;
        }

        return Promise.reject(error);
      });
  }
};
